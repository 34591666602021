import { API_TEMPLATE } from 'src/libs/constant'
import { post, upload as _upload, get } from 'src/libs/query'

export default {
  upload (file) {
    const form = new FormData() // eslint-disable-line
    form.append('file', file)
    const path = API_TEMPLATE.COMMON_UPLOAD_FILE

    return _upload(path, form)
  },

  createNotification (payload) {
    const path = API_TEMPLATE.COMMON_ZALO_NOTIFICATON_CREATE
    return post(path, payload)
  },

  filterNotification (payload) {
    const path = API_TEMPLATE.COMMON_ZALO_NOTIFICATON_FILTER
    return post(path, payload)
  },

  getLog ({ typeName, classId }) {
    return get(`${API_TEMPLATE.COMMON_LOG_CHANGE}?typeName=${typeName}&classId=${classId}`)
  },

  getOldLog (classId) {
    return get(`${API_TEMPLATE.CLASS_LOG_CHANGE}?classId=${classId}`)
  },

  getLevel () {
    return get(`${API_TEMPLATE.COMMON_GET_LEVEL}`)
  },

  getGrade () {
    return get(`${API_TEMPLATE.COMMON_GET_GRADE}`)
  },

  getDevice () {
    return get(`${API_TEMPLATE.COMMON_GET_DEVICE}`)
  },

  getProvince () {
    return get(`${API_TEMPLATE.COMMON_GET_PROVINCE}`)
  },

  getRegion () {
    return get(`${API_TEMPLATE.COMMON_GET_REGION}`)
  },

  getSubject () {
    return get(`${API_TEMPLATE.COMMON_GET_SUBJECT}`)
  },

  getStudyProgram () {
    return get(`${API_TEMPLATE.COMMON_GET_STUDY_PROGRAM}`)
  },

  getCrmPackage () {
    return get(`${API_TEMPLATE.COMMON_GET_CRM_PACKAGE}`)
  },

  getTimeSever () {
    return get(`${API_TEMPLATE.TIME_SEVER}`)
  },

  getViolationLevel () {
    return get(`${API_TEMPLATE.VIOLATION_LEVEL}`)
  },

  getDateMonthlyExam() {
    return get(`${API_TEMPLATE.COMMON_DATE_MONTHLY_EXAM}`)
  }
}
