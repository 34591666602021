export const CATEGORY_ACTIONS = {
  SET_CATEGORIES: 'SET_CATEGORIES'
}

export function setCategories (payload) {
  return {
    type: CATEGORY_ACTIONS.SET_CATEGORIES,
    payload: payload
  }
}
